import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { parseQuery, stringifyQuery } from "../helpers/qs"
import { formatMinutes } from "../helpers/timeFormatting"
import { connect } from "react-redux"
import moment from "moment"
import { sortArrayByName } from "../helpers/arraySortByName"
import * as actions from "../actions"

import {
	PageLayout,
	PootsyButton,
	TimesheetTotalHoursMonth,
	PootsySubHeading,
} from "./FunctionalDesign"

import TimesheetInfos from "./TimesheetInfos"
import TimesheetActions from "./TimesheetActions"
import ModalLayout from "./ModalLayout"
import CompleteMonthPicker from "./CompleteMonthPicker"
import TimesheetCostLines from "./TimesheetCostLines"
import TimesheetExportModal from "./TimesheetExportModal"
import NewTimesheetEventModal from "./NewTimesheetEventModal"
import TimesheetIntermediateClosureModal from "./TimesheetIntermediateClosureModal"
import TimesheetsLeftColumn from "./TimesheetsLeftColumn"
import TimesheetsMainContent from "./TimesheetsMainContent"
import TimesheetsCosts from "./TimesheetsCosts"
import TimesheetsCommunication from "./TimesheetsCommunication"
import CustomizePDFModal from "./CustomizePDFModal"

import arrowRightIcon from "../static/images/arrow-right-icon.png"
import pdfIcon from "../static/images/pdf-icon.png"

class Timesheets extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cleanerSearchResults: [],
			timesheetFiltering: "all",
		}
	}
	componentDidMount = () => {
		let { dispatch, history } = this.props
		let query = parseQuery(window.location.search)
		if (query.month && moment(query.month, "YYYY-MM-DD").isValid()) {
			let month = moment(query.month, "YYYY-MM-DD").startOf("month")
			dispatch(actions.selectTimesheetsMonth(month))
			return
		}
		history.push({
			search: stringifyQuery({
				month: moment()
					.startOf("month")
					.format("YYYY-MM-DD"),
			}),
		})
		dispatch(actions.fetchTimesheetsData())
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetTimesheetsComponent())
	}
	handleStateChange = changes => {
		this.props.dispatch(actions.timesheetsStateChange(changes))
	}
	toggleEditMode = () => {
		let { timesheetsComponent, data } = this.props
		let sched = data.find(
			(sched, index) => sched.worker.id === timesheetsComponent.selectedWorker
		)
		if (
			!timesheetsComponent.editMode &&
			!timesheetsComponent.showPostClosureModificationWarningModal &&
			sched.timesheetInfo.closureDate
		) {
			this.handleStateChange({ showPostClosureModificationWarningModal: true })
			return
		}
		this.handleStateChange({ editMode: !timesheetsComponent.editMode })
	}
	dismissPostClosureModificationWarning = () => {
		this.handleStateChange({ showPostClosureModificationWarningModal: false, editMode: true })
	}
	closePostClosureModificationModal = () => {
		this.handleStateChange({ showPostClosureModificationWarningModal: false })
	}
	toggleNewEventModal = e => {
		let { dispatch, timesheetsComponent } = this.props
		if (e === undefined) {
			this.handleStateChange({ showNewEventModal: !timesheetsComponent.showNewEventModal })
			return
		}
		let { day } = e.target.dataset
		dispatch(
			actions.newTimesheetEventModalStateChange({
				newEventModalDay: moment(day),
				newEventSecSocTerm: "",
				newEventDistance: "",
				newEventStart: moment()
					.hour(8)
					.minute(0),
				newEventEnd: moment()
					.hour(12)
					.minutes(0),
			})
		)
		this.handleStateChange({ showNewEventModal: !timesheetsComponent.showNewEventModal })
	}
	toggleIntermediateClosureModal = () => {
		this.handleStateChange({
			showIntermediateClosureModal: !this.props.timesheetsComponent
				.showIntermediateClosureModal,
		})
	}
	toggleTimesheetExportModal = () => {
		this.handleStateChange({
			showTimesheetExportModal: !this.props.timesheetsComponent.showTimesheetExportModal,
		})
	}
	selectTab = e => {
		let { index } = e.target.dataset
		this.props.dispatch(actions.timesheetsStateChange({ selectedTab: Number(index) }))
	}
	handleTimesheetFilteringChange = e => {
		let {
			timesheetsComponent: {
				selectedWorker,
				closedTimesheets,
				openedTimesheets,
				upfrontPaymentTimesheets,
				timesheetsNeedingCorrection,
				timesheetsClosedAfterLastTsc,
			},
			data,
		} = this.props
		let { value } = e.target
		let currentTimesheet = data.find(sched => sched.worker.id === selectedWorker)
		let currentTimesheetIsClosed = !!currentTimesheet.timesheetInfo.timesheetID
		if (value === "closed") {
			let closedTimesheet = closedTimesheets.find(entry => entry.timesheet_id) // searching for an id that exists
			if (closedTimesheet) {
				if (!currentTimesheetIsClosed) {
					// If there are closed timesheets, and if the current timesheet is
					// opened, the first cleaner with a closed timesheet will be immediately selected
					// ...except the closeTimesheets array also contains object that represent timesheet
					// that do not exist (contracts without timesheets for this month)
					let sched = data.find(
						sched => sched.timesheetInfo.timesheetID === closedTimesheet.timesheet_id
					)
					this.props.dispatch(
						actions.timesheetsStateChange({ selectedWorker: sched.worker.id })
					)
				}
				this.setState({ timesheetFiltering: value })
			}
		} else if (value === "opened") {
			if (openedTimesheets.length > 0) {
				if (currentTimesheetIsClosed) {
					// If there are opened timesheets, and if the current timesheet is
					// closed, the first cleaner with an opened timesheet will be immediately selected
					let workerId = openedTimesheets[0].worker_contract_id
					this.props.dispatch(actions.timesheetsStateChange({ selectedWorker: workerId }))
					this.props.dispatch(actions.fetchTimesheetsData())
				}
				this.setState({ timesheetFiltering: value })
			}
		} else if (value === "upfrontPayment") {
			if (upfrontPaymentTimesheets.length > 0) {
				if (!currentTimesheet.needsUpfrontPayment) {
					let workerId = upfrontPaymentTimesheets[0].worker_contract_id
					this.props.dispatch(actions.timesheetsStateChange({ selectedWorker: workerId }))
					this.props.dispatch(actions.fetchTimesheetsData())
				}
				this.setState({ timesheetFiltering: value })
			}
		} else if (value === "closedAfterLastTsc") {
			if (timesheetsClosedAfterLastTsc.length > 0) {
				if (!currentTimesheet.closedAfterLastTsc) {
					let workerId = timesheetsClosedAfterLastTsc[0].worker_contract_id
					this.props.dispatch(actions.timesheetsStateChange({ selectedWorker: workerId }))
					this.props.dispatch(actions.fetchTimesheetsData())
				}
				this.setState({ timesheetFiltering: value })
			}
		} else if (value === "needingCorrection") {
			if (timesheetsNeedingCorrection.length > 0) {
				if (!currentTimesheet.needsCorrectionWarning) {
					let workerId = timesheetsNeedingCorrection[0].worker_contract_id
					this.props.dispatch(actions.timesheetsStateChange({ selectedWorker: workerId }))
					this.props.dispatch(actions.fetchTimesheetsData())
				}
				this.setState({ timesheetFiltering: value })
			}
		} else {
			this.setState({ timesheetFiltering: value })
		}
	}
	handleSquadFilterChange = changes => {
		this.handleStateChange({ selectedSquads: changes.value })
		this.props.dispatch(actions.fetchTimesheetsData())
	}
	closePDFModal = () => {
		this.handleStateChange({ showPDFModal: false })
		this.toggleCustomizePDFModal()
	}
	handleScheduleModif = (timesheetID, workerId, day, eventIndex, changes) => {
		let { dispatch, timesheetsComponent, data } = this.props
		let scheduleIndex
		let sched = data.find((sched, index) => {
			scheduleIndex = index
			return sched.worker.id === timesheetsComponent.selectedWorker
		})
		let changedEvent = { ...sched.eventsForMonth[day][eventIndex] }
		let newEvent = { ...changedEvent, ...changes }
		let duration = moment.duration(newEvent.end_time.diff(newEvent.start_time))
		newEvent.duration = `${duration.hours()}:${
			duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()
		}`
		dispatch(actions.timesheetsDataChange(scheduleIndex, day, eventIndex, newEvent))
		dispatch(actions.submitTimesheetUpdate(timesheetID, day, newEvent))
	}
	handleNewEventChanges = changes => {
		this.setState({ ...changes })
	}
	softDeleteEvent = e => {
		let { day, eventindex } = e.target.dataset
		let { dispatch, timesheetsComponent, data } = this.props
		let scheduleIndex
		let sched = data.find((sched, index) => {
			scheduleIndex = index
			return sched.worker.id === timesheetsComponent.selectedWorker
		})
		let eventIndex = Number(eventindex)
		day = moment(day)
		let changedTimesheetId = sched.timesheetInfo.timesheetID
		let changedEvent = { ...sched.eventsForMonth[day.format("YYYY-MM-DD")][eventIndex] }
		let newEvent = { ...changedEvent, affiliate_deleted: true }
		dispatch(
			actions.timesheetsDataChange(
				scheduleIndex,
				day.format("YYYY-MM-DD"),
				eventIndex,
				newEvent
			)
		)
		dispatch(
			actions.submitTimesheetUpdate(changedTimesheetId, day.format("YYYY-MM-DD"), newEvent)
		)
	}
	downloadAllPdf = (codeSumsFromDate, codesSumsTimeSpan, codeSumsSquads, selectedPrintDate) => {
		let { dispatch } = this.props
		let toDate = moment(codeSumsFromDate).endOf(codesSumsTimeSpan)

		let body = {
			for_month: codeSumsFromDate.format("YYYY-MM-DD"),
			squad_ids: codeSumsSquads,
			print_date: selectedPrintDate.format("YYYY-MM-DD"),
			timesheet_id: "all",
		}

		if (codesSumsTimeSpan === "week") {
			body.for_week = toDate.format("YYYY-MM-DD")
			body.for_month = toDate
				.startOf("week")
				.startOf("month")
				.format() // week can be on 2 months
		}

		let init = { method: "POST", body: JSON.stringify(body) }
		dispatch(actions.requestTimesheetPDF(init))
	}
	downloadWorkerMonthPdf = (
		codeSumsFromDate,
		codesSumsTimeSpan,
		codeSumsSquads,
		selectedPrintDate
	) => {
		let {
			data,
			dispatch,
			timesheetsComponent: { selectedWorker, selectedMonth },
		} = this.props
		let currentSched = data.find(sched => sched.worker.id === selectedWorker)
		let init = {
			method: "POST",
			body: JSON.stringify({
				timesheet_id: currentSched.timesheetInfo.timesheetID,
				print_date: selectedPrintDate.format("YYYY-MM-DD"),
				for_month: selectedMonth.format(),
			}),
		}
		dispatch(actions.requestTimesheetPDF(init))
	}
	downloadWorkerWeekPdf = e => {
		let { weekstart } = e.target.dataset
		let { data, dispatch, timesheetsComponent } = this.props
		let { selectedWorker } = timesheetsComponent
		let currentSched = data.find(sched => sched.worker.id === selectedWorker)
		let init = {
			method: "POST",
			body: JSON.stringify({
				timesheet_id: currentSched.timesheetInfo.timesheetID,
				for_week: moment(weekstart).format("YYYY-MM-DD"),
				for_month: timesheetsComponent.selectedMonth.format(),
			}),
		}
		dispatch(actions.requestTimesheetPDF(init))
	}
	toggleCustomizePDFModal = origin => {
		let {
			dispatch,
			timesheetsComponent: { showCustomizePDFModal },
			teams,
		} = this.props
		if (teams.length === 0 && origin === "allWorkerTimsheets") {
			dispatch(actions.fetchTeams())
		}
		this.handleStateChange({
			showCustomizePDFModal: !showCustomizePDFModal,
			customizePDFModalOrigin: origin,
		})
	}
	createCodesSumsPdf = (codeSumsFromDate, codesSumsTimeSpan, codeSumsSquads) => {
		this.props.dispatch(
			actions.createCodesSumsPdf(
				this.context.t,
				codeSumsFromDate,
				codesSumsTimeSpan,
				codeSumsSquads
			)
		)
	}
	submitCloseMonth = () => {
		this.props.dispatch(actions.submitCloseTimesheet())
	}
	submitIntermediateClosureDate = intermediateClosureDate => {
		this.props.dispatch(actions.submitCloseTimesheet(intermediateClosureDate))
		this.toggleIntermediateClosureModal()
	}
	cancelIntermediateClosureDate = () => {
		let date = this.state.intermediateClosureDate
		this.props.dispatch(actions.submitReopenTimesheetWeek(date))
		this.handleStateChange({ showIntermediateClosureModal: false })
	}
	closeUnclosableTimesheetWarningModal = () => {
		this.handleStateChange({ showUnclosableTimesheetWarningModal: false })
	}
	closeRefreshCompareModal = () => {
		this.handleStateChange({ showRefreshCompareResults: false })
	}
	handleIntermediateClosureDateChange = changes => {
		this.setState(changes)
	}
	selectMonth = newMonth => {
		this.setState({ timesheetFiltering: "all" })
		this.props.dispatch(actions.selectTimesheetsMonth(newMonth))
	}
	searchWorkers = e => {
		let { value } = e.target
		let {
			timesheetsComponent: {
				activeWorkersForMonth,
				closedTimesheets,
				openedTimesheets,
				upfrontPaymentTimesheets,
			},
		} = this.props
		let { timesheetFiltering } = this.state
		let searchArray = []
		if (value.length > 0) {
			if (timesheetFiltering !== "all") {
				if (timesheetFiltering === "closed") {
					searchArray = closedTimesheets
				} else if (timesheetFiltering === "opened") {
					searchArray = openedTimesheets
				} else if (timesheetFiltering === "upfrontPayment") {
					searchArray = upfrontPaymentTimesheets
				}
			} else {
				searchArray = activeWorkersForMonth
			}
		}
		searchArray = sortArrayByName(searchArray, "display_name")
		let cleanerSearchResults = searchArray.reduce((acc, worker) => {
			let name = worker.worker_name || worker.display_name
			if (name.toLowerCase().includes(value.toLowerCase())) {
				const cStart = worker.contractStart.format("DD/MM/YY")
				const cEnd = worker.contractEnd ? moment(worker.contractEnd).format("DD/MM/YY") : ""
				acc.push({
					label: (
						<div style={{ pointerEvents: "none" }}>
							<div>{name}</div>
							<div className="contract-dates">
								Start: {cStart} {cEnd ? `/ End: ${cEnd}` : ""}
							</div>
						</div>
					),
					value: worker.worker_contract_id,
				})
			}
			return acc
		}, [])
		this.setState({ cleanerSearchResults })
	}
	selectWorker = e => {
		let { value } = e.target.dataset
		this.setState({ cleanerSearchResults: [] })
		this.props.dispatch(actions.selectTimesheetWorker(Number(value)))
	}
	refetchCurrentWorker = () => {
		this.props.dispatch(
			actions.selectTimesheetWorker(this.props.timesheetsComponent.selectedWorker)
		)
	}
	selectNextWorker = () => {
		let {
			timesheetsComponent: {
				selectedWorker,
				activeWorkersForMonth,
				openedTimesheets,
				closedTimesheets,
				upfrontPaymentTimesheets,
				timesheetsNeedingCorrection,
				timesheetsClosedAfterLastTsc,
			},
		} = this.props
		let { timesheetFiltering } = this.state
		let newWorkerID = 0
		if (timesheetFiltering === "closed") {
			newWorkerID = closedTimesheets[0].worker_contract_id
			closedTimesheets.forEach((entry, index) => {
				if (entry.worker_contract_id === selectedWorker && closedTimesheets[index + 1]) {
					newWorkerID = closedTimesheets[index + 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "opened") {
			newWorkerID = openedTimesheets[0].worker_contract_id
			openedTimesheets.forEach((entry, index) => {
				if (entry.worker_contract_id === selectedWorker && openedTimesheets[index + 1]) {
					newWorkerID = openedTimesheets[index + 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "upfrontPayment") {
			newWorkerID = upfrontPaymentTimesheets[0].worker_contract_id
			upfrontPaymentTimesheets.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					upfrontPaymentTimesheets[index + 1]
				) {
					newWorkerID = upfrontPaymentTimesheets[index + 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "closedAfterLastTsc") {
			newWorkerID = timesheetsClosedAfterLastTsc[0].worker_contract_id
			timesheetsClosedAfterLastTsc.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					timesheetsClosedAfterLastTsc[index + 1]
				) {
					newWorkerID = timesheetsClosedAfterLastTsc[index + 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "needingCorrection") {
			newWorkerID = timesheetsNeedingCorrection[0].worker_contract_id
			timesheetsNeedingCorrection.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					timesheetsNeedingCorrection[index + 1]
				) {
					newWorkerID = timesheetsNeedingCorrection[index + 1].worker_contract_id
				}
			})
		} else {
			newWorkerID = activeWorkersForMonth[0].worker_contract_id
			activeWorkersForMonth.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					activeWorkersForMonth[index + 1]
				) {
					newWorkerID = activeWorkersForMonth[index + 1].worker_contract_id
				}
			})
		}
		this.props.dispatch(actions.selectTimesheetWorker(newWorkerID))
	}
	selectPrevWorker = () => {
		let {
			timesheetsComponent: {
				selectedWorker,
				activeWorkersForMonth,
				openedTimesheets,
				closedTimesheets,
				upfrontPaymentTimesheets,
				timesheetsNeedingCorrection,
				timesheetsClosedAfterLastTsc,
			},
		} = this.props
		let { timesheetFiltering } = this.state
		let newWorkerID = 0
		if (timesheetFiltering === "closed") {
			newWorkerID = closedTimesheets[closedTimesheets.length - 1].worker_contract_id
			closedTimesheets.forEach((entry, index) => {
				if (entry.worker_contract_id === selectedWorker && closedTimesheets[index + 1]) {
					newWorkerID = closedTimesheets[index + 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "opened") {
			newWorkerID = openedTimesheets[openedTimesheets.length - 1].worker_contract_id
			openedTimesheets.forEach((entry, index) => {
				if (entry.worker_contract_id === selectedWorker && openedTimesheets[index - 1]) {
					newWorkerID = openedTimesheets[index - 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "upfrontPayment") {
			newWorkerID =
				upfrontPaymentTimesheets[upfrontPaymentTimesheets.length - 1].worker_contract_id
			upfrontPaymentTimesheets.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					upfrontPaymentTimesheets[index - 1]
				) {
					newWorkerID = upfrontPaymentTimesheets[index - 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "closedAfterLastTsc") {
			newWorkerID =
				timesheetsClosedAfterLastTsc[timesheetsClosedAfterLastTsc.length - 1]
					.worker_contract_id
			timesheetsClosedAfterLastTsc.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					timesheetsClosedAfterLastTsc[index - 1]
				) {
					newWorkerID = timesheetsClosedAfterLastTsc[index - 1].worker_contract_id
				}
			})
		} else if (timesheetFiltering === "needingCorrection") {
			newWorkerID =
				timesheetsNeedingCorrection[timesheetsNeedingCorrection.length - 1]
					.worker_contract_id
			timesheetsNeedingCorrection.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					timesheetsNeedingCorrection[index - 1]
				) {
					newWorkerID = timesheetsNeedingCorrection[index - 1].worker_contract_id
				}
			})
		} else {
			newWorkerID = activeWorkersForMonth[activeWorkersForMonth.length - 1].worker_contract_id
			activeWorkersForMonth.forEach((entry, index) => {
				if (
					entry.worker_contract_id === selectedWorker &&
					activeWorkersForMonth[index - 1]
				) {
					newWorkerID = activeWorkersForMonth[index - 1].worker_contract_id
				}
			})
		}
		this.props.dispatch(actions.selectTimesheetWorker(newWorkerID))
	}
	refreshCurrentTimesheet = () => {
		this.props.dispatch(actions.submitRefreshCurrentTimesheet())
	}
	reopenCurrentTimesheet = () => {
		this.props.dispatch(actions.submitReopenTimesheetMonth())
	}
	reopenAllTimesheets = () => {
		this.props.dispatch(actions.submitReopenAllTimesheets())
	}
	submitConfirmRefreshTimesheet = () => {
		this.props.dispatch(actions.submitConfirmRefreshCurrentTimesheet())
	}
	render() {
		let { t } = this.context
		let { user, timesheetsComponent, data, pdf, currAffiliate, teams } = this.props
		let { openedTimesheets } = timesheetsComponent
		let { cleanerSearchResults, timesheetFiltering } = this.state

		let currTimesheet = data.find(e => e.worker.id === timesheetsComponent.selectedWorker) || {
			worker: {},
			timesheetInfo: {},
			eventsForMonth: [],
			codesStats: {},
		}

		let currNanoTimesheet = openedTimesheets.find(
			e => e.worker_contract_id === currTimesheet.worker.id
		)
		let contractFamilyStart
		let contractFamilyEnd

		if (currNanoTimesheet) {
			contractFamilyStart = currNanoTimesheet.contractFamilyStart
			contractFamilyEnd = currNanoTimesheet.contractFamilyEnd
		}

		let currentMonth = moment(timesheetsComponent.selectedMonth)
		let pageHeaderLeft = (
			<h1 className="page-title secsoc-schedules">{t("secsoc_schedules")}</h1>
		)
		let pageHeaderRight = (
			<div className="sec-soc-sched-header-buttons">
				<PootsyButton
					theme="cancel"
					size="small"
					text={t("reopen_all_timesheets")}
					onClick={this.reopenAllTimesheets}
				/>
				{currAffiliate.secSocAddOn && (
					<Fragment>
						<PootsyButton
							customClass="codes-sums-button"
							theme="cancel"
							size="small"
							text={t("sec_soc_download_codes_sums_pdf")}
							onClick={() => this.toggleCustomizePDFModal("codesSums")}
						/>
						<PootsyButton
							text={t("export_to_sec_soc")}
							size="small"
							theme="cancel"
							onClick={this.toggleTimesheetExportModal}
						/>
					</Fragment>
				)}
				<PootsyButton
					customClass="dl-pdf-button"
					theme="cancel"
					size="small"
					text={[
						<img key="a" alt="pdf" src={pdfIcon} />,
						t("sec_soc_download_all_sched"),
					]}
					onClick={() => this.toggleCustomizePDFModal("allWorkerTimsheets")}
				/>
				<PootsyButton
					theme="cancel"
					size="small"
					text={t("refresh_timesheet")}
					onClick={this.refreshCurrentTimesheet}
				/>
				<PootsyButton
					theme="cancel"
					size="small"
					text={t("reopen_timesheet")}
					onClick={this.reopenCurrentTimesheet}
				/>
				{process.env.NODE_ENV === "development" && (
					<PootsyButton
						theme="cancel"
						size="small"
						text={"Refetch"}
						onClick={this.refetchCurrentWorker}
					/>
				)}
			</div>
		)
		let PDFmodalButton = [
			<a
				key="a"
				className="pdf-download-button"
				href={pdf.url}
				download={`schedules-${currentMonth.format("MMMM")}.pdf`}
			>
				<PootsyButton
					text={pdf.url ? t("save_pdf_button") : t("close")}
					onClick={this.closePDFModal}
				/>
			</a>,
		]
		let postClosureWarningModalButtons = [
			<PootsyButton
				key="a"
				text={t("yes")}
				onClick={this.dismissPostClosureModificationWarning}
			/>,
			<PootsyButton
				key="b"
				text={t("no")}
				onClick={this.closePostClosureModificationModal}
			/>,
		]
		let unclosableTimesheetWarningModalButtons = (
			<PootsyButton
				key="b"
				text={t("dismiss")}
				onClick={this.closeUnclosableTimesheetWarningModal}
			/>
		)
		let tabs = ["bookings", "costs"]
		if (currAffiliate.secSocName === "Group S") {
			tabs.push("communication")
		}
		return (
			<PageLayout headerLeft={pageHeaderLeft} headerRight={pageHeaderRight}>
				{timesheetsComponent.showCustomizePDFModal && (
					<CustomizePDFModal
						title={
							{
								codeSums: t("timesheet_codes_sums_modal_title"),
								allWorkerTimsheets: t("sec_soc_download_all_sched"),
								workerTimesheet: t("sec_soc_download_month_sched"),
							}[timesheetsComponent.customizePDFModalOrigin]
						}
						teams={teams}
						toggleCustomizePDFModal={this.toggleCustomizePDFModal}
						origin={timesheetsComponent.customizePDFModalOrigin}
						onConfirm={
							{
								codesSums: this.createCodesSumsPdf,
								allWorkerTimsheets: this.downloadAllPdf,
								workerTimesheet: this.downloadWorkerMonthPdf,
							}[timesheetsComponent.customizePDFModalOrigin]
						}
					/>
				)}
				{timesheetsComponent.showRefreshCompareResults && (
					<ModalLayout
						title={t("timesheet_refresh_compare_results")}
						closeModal={this.closeRefreshCompareModal}
						customClass="refresh-compare-modal"
						buttons={[
							<PootsyButton
								key="a"
								text={t("refresh")}
								onClick={this.submitConfirmRefreshTimesheet}
							/>,
							<PootsyButton
								key="b"
								theme="cancel"
								text={t("cancel")}
								onClick={this.closeRefreshCompareModal}
							/>,
						]}
					>
						<div className="original-timesheet">
							<PootsySubHeading text={t("original_timesheet")} />
							<TimesheetTotalHoursMonth
								currTimesheet={
									timesheetsComponent.refreshCompareResults.original_timesheet
								}
								customClass="refreshed-compare"
							/>
							<div className="timesheets-costs">
								<TimesheetCostLines
									currTimesheet={
										timesheetsComponent.refreshCompareResults.original_timesheet
									}
									secSocEvents={currAffiliate.secSocEvents}
									disableChanges={true}
								/>
							</div>
						</div>
						<div className="refreshed-timesheet">
							<PootsySubHeading text={t("refreshed_timesheet")} />
							<TimesheetTotalHoursMonth
								currTimesheet={
									timesheetsComponent.refreshCompareResults.refreshed_timesheet
								}
								customClass="refreshed-compare"
							/>
							<div className="timesheets-costs">
								<TimesheetCostLines
									currTimesheet={
										timesheetsComponent.refreshCompareResults
											.refreshed_timesheet
									}
									secSocEvents={currAffiliate.secSocEvents}
									disableChanges={true}
								/>
							</div>
						</div>
					</ModalLayout>
				)}
				{timesheetsComponent.showPDFModal && (
					<ModalLayout
						title={t("secsoc_pdf")}
						closeModal={this.closePDFModal}
						buttons={PDFmodalButton}
						formDivClass="pdf-modal"
					>
						{pdf.url && (
							<embed className="pdf-preview" src={pdf.url} type="application/pdf" />
						)}
						{!pdf.url && <div>{t(timesheetsComponent.text)}</div>}
					</ModalLayout>
				)}
				{timesheetsComponent.showNewEventModal && (
					<NewTimesheetEventModal
						closeModal={this.toggleNewEventModal}
						currTimesheet={currTimesheet}
						secSocEvents={currAffiliate.secSocEvents}
					/>
				)}
				{timesheetsComponent.showPostClosureModificationWarningModal && (
					<ModalLayout
						formDivClass="secsoc-post-closure-modification-warning-modal"
						title={t("warning")}
						buttons={postClosureWarningModalButtons}
						closeModal={this.closePostClosureModificationModal}
					>
						{t("post_closure_modification_warning")}
					</ModalLayout>
				)}
				{timesheetsComponent.showUnclosableTimesheetWarningModal && (
					<ModalLayout
						formDivClass="secsoc-unclosable-timesheet-warning-modal"
						title={t("warning")}
						buttons={unclosableTimesheetWarningModalButtons}
						closeModal={this.closeUnclosableTimesheetWarningModal}
					>
						{t("unclosable_timesheet_warning")}
					</ModalLayout>
				)}
				{timesheetsComponent.showIntermediateClosureModal && (
					<TimesheetIntermediateClosureModal
						currentMonth={currentMonth}
						closeModal={this.toggleIntermediateClosureModal}
						submitIntermediateClosureDate={this.submitIntermediateClosureDate}
					/>
				)}
				{timesheetsComponent.showTimesheetExportModal && (
					<TimesheetExportModal closeModal={this.toggleTimesheetExportModal} />
				)}
				<div className="secsoc-schedules-wrapper">
					<TimesheetsLeftColumn
						timesheetFiltering={timesheetFiltering}
						cleanerSearchResults={cleanerSearchResults}
						currTimesheet={currTimesheet}
						selectedWorker={timesheetsComponent.selectedWorker}
						openedTimesheets={openedTimesheets}
						closedTimesheets={timesheetsComponent.closedTimesheets}
						upfrontPaymentTimesheets={timesheetsComponent.upfrontPaymentTimesheets}
						timesheetsNeedingCorrection={
							timesheetsComponent.timesheetsNeedingCorrection
						}
						timesheetsClosedAfterLastTsc={
							timesheetsComponent.timesheetsClosedAfterLastTsc
						}
						handleTimesheetFilteringChange={this.handleTimesheetFilteringChange}
						searchWorkers={this.searchWorkers}
						selectWorker={this.selectWorker}
						currAffiliate={currAffiliate}
						selectedSquads={timesheetsComponent.selectedSquads}
						handleSquadFilterChange={this.handleSquadFilterChange}
					/>
					<div className="secsoc-schedules right-part">
						<div className="secsoc-schedules-page-top">
							<div className="secsoc-schedules-selection-info-block">
								<CompleteMonthPicker
									currentDate={timesheetsComponent.selectedMonth}
									filter={m => m.isBefore(moment().add(1, "month"))}
									onChange={this.selectMonth}
								/>
								{data.length > 0 && (
									<div className="worker-block">
										<img
											alt="arrow-left"
											className="arrow-left"
											src={arrowRightIcon}
											onClick={this.selectPrevWorker}
										/>
										<div className="center">
											<div
												className={
													"worker-name" +
													(currTimesheet.worker.needsUpfrontPayment
														? " needs-upfront-payment"
														: "")
												}
											>
												{currTimesheet.worker.displayName}
											</div>
											{contractFamilyStart && (
												<div className="worker-contract-dates">
													{contractFamilyStart.format("DD/MM/YYYY")} -{" "}
													{contractFamilyEnd
														? contractFamilyEnd.format("DD/MM/YYYY")
														: t("no_end_date")}
												</div>
											)}
										</div>
										<img
											alt="arrow-right"
											className="arrow-right"
											src={arrowRightIcon}
											onClick={this.selectNextWorker}
										/>
									</div>
								)}
							</div>
							{currTimesheet.workInterruptionWarnings && (
								<div
									className="work-interruption-warning"
									style={{
										color: currTimesheet.timesheetInfo.closureDate
											? "initial"
											: "red",
									}}
								>
									{currTimesheet.workInterruptionWarnings.map(entry => (
										<div key={entry.week_nb}>
											{t("work_interruption_warning", {
												week_nb: entry.week_nb,
												expected: formatMinutes(entry.expected_hours),
												actual: formatMinutes(entry.actual_minutes),
											})}
										</div>
									))}
								</div>
							)}
							<div className="secsoc-schedules-top-part">
								<TimesheetInfos timesheet={currTimesheet} />
								<TimesheetActions
									timesheet={currTimesheet}
									submitCloseMonth={this.submitCloseMonth}
									toggleIntermediateClosureModal={
										this.toggleIntermediateClosureModal
									}
									cancelIntermediateClosureDate={
										this.cancelIntermediateClosureDate
									}
									toggleEditMode={this.toggleEditMode}
									toggleCustomizePDFModal={this.toggleCustomizePDFModal}
									user={user}
								/>
							</div>
						</div>
						<div className="secsoc-tabs">
							{tabs.map((entry, index) => (
								<div
									key={index}
									className={
										"tab" +
										(index === timesheetsComponent.selectedTab ? " active" : "")
									}
									data-index={index}
									onClick={this.selectTab}
								>
									{t(entry)}
								</div>
							))}
						</div>
						{timesheetsComponent.selectedTab === 0 && (
							<TimesheetsMainContent
								editMode={timesheetsComponent.editMode}
								currTimesheet={currTimesheet}
								currAffiliate={currAffiliate}
								secSocEvents={currAffiliate.secSocEvents}
								handleScheduleModif={this.handleScheduleModif}
								toggleNewEventModal={this.toggleNewEventModal}
								softDeleteEvent={this.softDeleteEvent}
								downloadWorkerWeekPdf={this.downloadWorkerWeekPdf}
							/>
						)}
						{timesheetsComponent.selectedTab === 1 && (
							<TimesheetsCosts currTimesheet={currTimesheet} />
						)}
						{timesheetsComponent.selectedTab === 2 && (
							<TimesheetsCommunication currTimesheet={currTimesheet} />
						)}
					</div>
				</div>
			</PageLayout>
		)
	}
}

Timesheets.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	lang: state.i18nState.lang,
	data: state.redData.timesheetsData,
	timesheetsComponent: state.redComponents.timesheetsComponent,
	pdf: state.redData.secSocPdf,
	currAffiliate: state.redData.currentAffiliate,
	teams: state.redData.teams,
	user: state.redData.currentUser,
})
export default connect(mapStateToProps)(Timesheets)
