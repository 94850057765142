import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import * as actions from "../actions"

import Filter from "./Filter"
import {
	OptionBox,
	ContentLayout,
	LabeledSimpleLine,
	PootsySubHeading,
	OnssErrorModal,
	PootsyButton,
	ConfirmationModal,
	NoCSSInfoBox,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import { downloadFileFromUrl } from "../helpers/downloadFileFromUrl"

import DateFilterer from "./DateFilterer"

import SearchWorker from "./SearchWorker"

class UnemploymentDeclarations extends Component {
	UNSAFE_componentWillMount = () => {
		this.props.dispatch(actions.fetchUnemploymentDeclarations())
		if (this.props.teams.length === 0) {
			this.props.dispatch(actions.fetchTeams())
		}
	}
	handleChanges = changes => {
		this.props.dispatch(actions.unemploymentDeclarationsStateChange(changes))
	}
	handleFilterWorker = workerName => {
		this.handleChanges({ searchedAffiliateWorker: { name: workerName } })
		if (workerName === "" || workerName === null) {
			this.handleChanges({ searchedAffiliateWorkerId: null })
			this.props.dispatch(actions.fetchUnemploymentDeclarations())
		}
	}
	handleFilterStatus = e => {
		let { value } = e.target
		this.handleChanges({ status: value })
		this.props.dispatch(actions.fetchUnemploymentDeclarations())
	}
	handleSquadsFilterChange = changes => {
		this.handleChanges({ squadsFilter: changes.value })
		this.props.dispatch(actions.fetchUnemploymentDeclarations())
	}
	toggleOnssErrorModal = id => {
		let {
			component: { declarations },
		} = this.props
		let decla = declarations.find(entry => entry.id === id)
		let changes = {}
		if (!decla || !id || !decla.attributes.errors) {
			changes = { showOnssErrorModal: false, onssErrors: "" }
		} else {
			changes = {
				showOnssErrorModal: true,
				onssErrors: decla.attributes.errors,
				onssErrorsTradKeys: decla.attributes.error_translation_keys,
			}
		}
		this.handleChanges(changes)
	}
	openCancelUDwarningModal = id => {
		let {
			component: { declarations },
		} = this.props
		let decla = declarations.find(entry => entry.id === id)
		this.handleChanges({
			showCancelUDwarningModal: true,
			udStagedForCancelling: decla.id,
		})
	}
	closeCancelUDwarningModal = id => {
		this.handleChanges({ showCancelUDwarningModal: false })
	}
	handleWorkerClick = worker => {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			firstName: worker.attributes.first_name,
			lastName: worker.attributes.last_name,
			sodexo: worker.attributes.sodexo_reference,
			niss: worker.attributes.niss,
			affiliateWorkerId: Number(worker.id),
		}
		this.handleChanges({ chosenWorker: newChosenWorker })
		this.props.dispatch(actions.resetWorkerSuggestions())
	}
	handleFilterWorkerClick = worker => {
		this.handleChanges({
			searchedAffiliateWorkerId: Number(worker.id),
			searchedAffiliateWorker: { name: worker.attributes.display_name },
		})
		this.props.dispatch(actions.fetchUnemploymentDeclarations())
	}
	handleDateFilterChange = changes => {
		let newObj = { rangeStart: changes.from, rangeEnd: changes.to }
		this.handleChanges(newObj)
		this.props.dispatch(actions.fetchUnemploymentDeclarations())
	}
	fetchDeclarationPDF = decla => {
		if (decla.attributes.pdf_url) {
			downloadFileFromUrl(
				decla.attributes.pdf_url,
				`#${decla.id} - ${decla.attributes.affiliate_worker} unemployment declaration.pdf`
			)
			return
		}
		this.props.dispatch(actions.fetchDeclarationPDF(decla.id))
	}
	submitCancelUd = () => {
		this.props.dispatch(actions.submitCancelUd())
	}
	render() {
		let { t } = this.context
		let {
			teams,
			component: {
				declarations,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorker,
				showOnssErrorModal,
				onssErrors,
				onssErrorsTradKeys,
				squadsFilter,
				status,
				showCancelUDwarningModal,
			},
		} = this.props
		let optionsBoxes = (
			<OptionBox
				boxTitle={t("eco_unemployment_declarations")}
				customClass="worker-contracts-list"
			>
				<PootsySubHeading text={t("date_filters")} />
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					// rangeLimitEnd={}
					onChange={this.handleDateFilterChange}
				/>
				<SearchWorker
					onChange={this.handleFilterWorker}
					onClick={this.handleFilterWorkerClick}
					value={searchedAffiliateWorker.name}
				/>
				<PootsySelectInput
					name="status"
					defaultText={t("status")}
					selected={status}
					options={actions.ONSS_STATUSES.map(e => ({ label: t(e), value: e }))}
					onChange={this.handleFilterStatus}
					allowEmpty={true}
				/>
				<Filter
					label={t("squads")}
					name="squadsFilter"
					possibleValues={teams.map(entry => ({
						label: entry.attributes.name,
						value: entry.id,
					}))}
					currentFilters={squadsFilter}
					onChange={this.handleSquadsFilterChange}
				/>
			</OptionBox>
		)

		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="UnemploymentDeclarations">
				{showOnssErrorModal && (
					<OnssErrorModal
						errors={onssErrors}
						errorsTradKeys={onssErrorsTradKeys}
						closeModal={this.toggleOnssErrorModal}
					/>
				)}
				{showCancelUDwarningModal && (
					<ConfirmationModal
						title={t("cancel_unemployment_declaration_warning")}
						closeModal={this.closeCancelUDwarningModal}
						yesButton={<PootsyButton text={t("yes")} onClick={this.submitCancelUd} />}
						noButton={
							<PootsyButton
								text={t("no")}
								theme="cancel"
								onClick={this.closeCancelUDwarningModal}
							/>
						}
					/>
				)}
				{declarations &&
					declarations.map(entry => (
						<Fragment key={entry.id}>
							<LabeledSimpleLine
								simpleLineProps={{
									noLink: true,
									customClass: "unemployment-declaration-line",
									onClick: () => this.toggleOnssErrorModal(entry.id),
								}}
								columns={[
									{ noLabel: true, content: "#" + entry.id, customClass: "id" },
									{
										label: t("onss_ref_number"),
										value: entry.attributes.onss_ref_number,
									},
									{
										label: t("start_date"),
										value: moment(entry.attributes.start_date).format(
											"DD/MM/YYYY"
										),
									},
									{
										label: t("status"),
										value: entry.attributes.status
											? t(entry.attributes.status)
											: t("onss_connection_not_yet"),
									},
									{
										label: t("address"),
										value: entry.attributes.address,
									},
									{
										label: t("final_decision"),
										value: entry.attributes.final_decision
											? entry.attributes.final_decision
											: "-",
									},
									{
										label: t("worker"),
										value: entry.attributes.affiliate_worker,
									},
									{
										noLabel: true,
										content: (
											<PootsyButton
												text={t("fetch_eco_declaration_pdf")}
												size="small"
												disabled={
													!(
														entry.attributes.base64_pdf_available ||
														entry.attributes.pdf_url
													)
												}
												onClick={() => this.fetchDeclarationPDF(entry)}
											/>
										),
									},
									{
										noLabel: true,
										content: (
											<PootsyButton
												text={t("cancel_eco_declaration")}
												size="small"
												theme="cancel"
												disabled={!entry.attributes.cancellable}
												onClick={() =>
													this.openCancelUDwarningModal(entry.id)
												}
											/>
										),
									},
								]}
							/>
							{entry.attributes.cancelled && (
								<div className="cancelled-infos">
									<div className="line-title">
										{t("unemp_decla_cancelled_line_title")}
									</div>
									{entry.attributes.cancelling_infos.map(entry => (
										<NoCSSInfoBox
											label={t(entry.translation_key)}
											value={entry.value}
										/>
									))}
								</div>
							)}
						</Fragment>
					))}
				{declarations && declarations.length === 0 && <div> {t("empty_list")} </div>}
			</ContentLayout>
		)
	}
}

UnemploymentDeclarations.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.unemploymentDeclarationsComponent,
	teams: state.redData.teams,
})
export default connect(mapStateToProps)(UnemploymentDeclarations)
